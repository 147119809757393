<template>
  <vue-final-modal v-model="showModal" @closed="closeModal">
    <form class="modal-form__box form" @submit.prevent>
      <button class="modal-form__close js-close" type="button" @click="closeModal">
        <svg class="s-icon s-icon--largest">
          <use xlink:href="/img/svg/sprite1.svg#cancel" />
        </svg>
        <span class="visually-hidden">Закрыть модальное окно</span>
      </button>
      <div class="modal-form__title">
        Настройка напоминаний
      </div>
      <div class="remind-days-box">
        <p class="remind-days-box__message">Настройте интервал для получения уведомлений</p>
        <form class="search-block__form" @submit.prevent>
              <div class="search-block__label">
                <label class="field field--icon modal-form__field" :class="{ 'v-error': form.errors.type }">
                <span class="field__suptitle">Как часто нужно напоминать?</span>
                <vue-multiselect
                  v-model="form.body.typeValue"
                  :options="types"
                  trackBy="id"                                 
                  :multiple="false"
                  :close-on-select="true"
                  :searchable="false"
                  :show-labels="false"
                  :internal-search="false"
                  placeholder="Укажите тип напоминания"
                  @select="changeType"
                >                
                <template #singleLabel="{ option }">
                    <div class="select__subtitle">
                      {{ option.name }}
                    </div>
                  </template>
                  <template #option="{ option }">
                    <div class="select__subtitle">
                      {{ option.name }}
                    </div>
                  </template>             
                </vue-multiselect>
                <span class="error-field" v-if = "form.errors.type">
                    {{ form.errors.type }}
                </span>
              </label>
              </div>
              <p> </p>
              <p> </p>
              <div class="search-block__label" v-if="form.body.type == DAY_OF_WEEK">
                <label class="field field--icon modal-form__field" :class="{ 'v-error': form.errors.days && form.body.type == DAY_OF_WEEK}">
                <span class="field__suptitle">Дни недели</span>
                <vue-multiselect
                  v-model="form.body.daysOfWeek"
                  :options="weekDays"
                  trackBy="id"                                 
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :searchable="false"
                  :show-labels="false"
                  :internal-search="false"
                  placeholder="Дни напоминаний"
                  label="name"
                  track-by="name"
                >                                        
                </vue-multiselect>
                <span class="error-field" v-if = "form.errors.days && form.body.type == DAY_OF_WEEK">
                    {{ form.errors.days }}
                </span>
              </label>
              </div>
              <p> </p>
              <p> </p>
              <div class="search-block__label" v-if="form.body.type == DAY_OF_MONTH">
                <label class="field field--icon modal-form__field" :class="{ 'v-error': form.errors.days && form.body.type == DAY_OF_MONTH}">
                <span class="field__suptitle">Дни месяца</span>
                <vue-multiselect
                  v-model="form.body.days"
                  :options="monthDays"                                             
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :searchable="false"
                  :show-labels="false"
                  :internal-search="false"
                  placeholder="Дни напоминаний"
                >                                        
                </vue-multiselect>
                <span class="error-field" v-if = "form.errors.days && form.body.type == DAY_OF_MONTH">
                    {{ form.errors.days }}
                </span>
              </label>
              </div>
        </form>
      </div>
      <button :disabled="isSubmitting" class="btn btn--primary btn--large" @click.prevent="updateRemind">
        Сохранить
      </button>
    </form>
  </vue-final-modal>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import { catchFormError } from '@/common/catchFormError'
import { computed, reactive, ref } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { useApi } from '@/api'
import { useToast } from 'vue-toastification'
import { getDaysAsObject, isDaySelected, setDaysOfWeek } from '@/common/remindDays'
import { getTypesAsObject, getTypeAsObject, DAY_OF_WEEK, DAY_OF_MONTH } from '@/common/remindType'

export default {
  components: {
    VueMultiselect,
    VueFinalModal
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    remind: {
      Type: Object,
      required: true
    }
  },
  emits: ['update:modelValue', 'update:Remind'],
  setup(props, { emit }) {
    const showModal = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:modelValue', value)
      }
    })
    const remind = computed({
      get() {
        return props.remind
      }    
    })    
    const form = reactive({
      body: {
        shipping_address_id: remind.value.id,
        type: remind.value.type,
        days: [...remind.value.days],
        typeValue: getTypeAsObject(remind.value.type),
        daysOfWeek: remind.value.type == DAY_OF_WEEK && remind.value.days.length > 0 ? setDaysOfWeek(remind.value.days) : [],
        daysOfMonth: remind.value.type == DAY_OF_MONTH && remind.value.days.length > 0 ? remind.value.days : [],
      },
      errors: {}
    })

    const isSubmitting = ref(false)
    const api = useApi()
    const toast = useToast()
    const updateRemind = () => {
      isSubmitting.value = true
      if (form.body.type == DAY_OF_WEEK && form.body.daysOfWeek.length > 0) {
        form.body.days = form.body.daysOfWeek.map(day => day.id)
      }
      api.profile.updateRemind(form.body).then((data) => {
        emit('update:Remind', data.data)
        closeModal()
        toast.success('Изменения сохранены')
      }).catch(catchFormError(form, toast)).finally(() => {
        isSubmitting.value = false
      })
    }
    const closeModal = () => {
      showModal.value = false
    }

    const types = getTypesAsObject()
    const weekDays = getDaysAsObject()
    const monthDays = Array.from({ length: 30 }, (_, i) => i + 1)
    const changeType = (selectedOption) => {
      form.body.type = selectedOption.id
      form.body.typeValue = selectedOption
      if (form.body.type == DAY_OF_MONTH) {
        form.body.days = []
      }
    }

    return {
      isSubmitting,
      form,
      closeModal,
      updateRemind,
      showModal,
      isDaySelected,
      types,
      changeType,
      weekDays,
      monthDays,
      DAY_OF_MONTH,
      DAY_OF_WEEK
    }
  }
}
</script>