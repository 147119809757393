<template>
  <div class="address-table__inner">
    <div class="table address-table">
      <ul class="address-table__header">
        <li class="address-table__title address-table__title--first">
          Наименование
        </li>
        <li class="address-table__title address-table__title--second">
          Как часто?
        </li>
        <li class="address-table__title address-table__title--third">
          В какие дни напоминать?
        </li>
        <li class="address-table__title address-table__title--forth"> </li>
      </ul>
      <template v-if="addresses?.length">
        <ul class="address-table__list">
          <address-list-item v-for="address in addresses" :key="address.id" :address="address" />
        </ul>
      </template>
      <template v-else>
        <p class="not-found-message">
          У вас нет адресов доставки
        </p>
      </template>
    </div>
  </div>  
</template>

<script>
import AddressListItem from './AddressListItem.vue'

export default {
  components: {
    AddressListItem
  },
  props: {
    addresses: {
      type: Array,
      required: true
    }
  },
  setup() { 

    return {
      
    }
  }
}
</script>
