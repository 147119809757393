<template>
  <li class="address-table__item">
    <div class="address-table__col address-table__col--first">
      <div class="address-table__text">
        {{ remind.name }}
      </div>
    </div>
    <div class="address-table__col address-table__col--second">
      <div class="address-table__text">
          {{ remind.type >= 0 ? getTitle(remind.type) : '-' }}
      </div>
    </div>
    <div class="address-table__col address-table__col--third">
      <div class="address-table__text" v-if="remind.type == DAY_OF_WEEK">        
          {{ getShortTitles(remind.days) }}
      </div>
      <div class="address-table__text" v-else-if="remind.type == DAY_OF_MONTH">        
           {{ 'Каждое ' + remind.days.join(', ') }} - число месяца
      </div>
      <div class="address-table__text" v-else>        
          -
      </div>
    </div>
    <div class="address-table__col address-table__col--forth">
        <div class="address-table__docs">
          <svg class="s-icon s-icon--largest" @click="showRemindModal = true">
            <use xlink:href="/img/svg/sprite1.svg#edit" />
          </svg>
      </div>
    </div>
  </li>
  <remind-modal v-model="showRemindModal" :remind="remind" v-on:update:Remind="update"/>
</template>
<script>

import { getShortTitles } from '@/common/remindDays'
import { getTitle } from '@/common/remindType'
import { reactive, ref } from 'vue'
import RemindModal from '@/components/RemindModal.vue'
import { DAY_OF_WEEK, DAY_OF_MONTH } from '@/common/remindType'

export default {
  props: {
    address: {
      type: Object,
      required: true,
    }
  },
  components: {
    RemindModal
  },
  setup(props) {
    const showRemindModal = ref(false)
    let remind = reactive({
      id: props.address.id,
      type: props.address.reminder ? props.address.reminder.type : null,
      name: props.address.name,
      days: props.address.reminder && props.address.reminder.days ? props.address.reminder.days : []
    })

    const update = (data) => {      
      remind.days = data.days,
      remind.type = data.type
    }

    return {
      getShortTitles,
      getTitle,
      showRemindModal,
      remind,
      update,
      DAY_OF_WEEK,
      DAY_OF_MONTH
    }
  }
}
</script>
