export const SUNDAY = 0
export const MONDAY = 1
export const TUESDAY = 2
export const WEDNESDAY = 3
export const THURSDAY = 4
export const FRIDAY = 5
export const SATURDAY = 6

const titles = {
  [SUNDAY]: 'Воскресенье',
  [MONDAY]: 'Понедельник',
  [TUESDAY]: 'Вторник',
  [WEDNESDAY]: 'Среда',
  [THURSDAY]: 'Четверг',
  [FRIDAY]: 'Пятница',
  [SATURDAY]: 'Суббота'
}

const shortTitles = {
  [SUNDAY]: 'Вс',
  [MONDAY]: 'Пн',
  [TUESDAY]: 'Вт',
  [WEDNESDAY]: 'Ср',
  [THURSDAY]: 'Чт',
  [FRIDAY]: 'Пт',
  [SATURDAY]: 'Сб'
}

export const getDayTitle = (day) => {
  return titles[day]
}

export const isDaySelected = (days, day) => {
  return days.includes(day)
}

export const getShortTitles = (days) => {
  const selectedDays = []

  if (!days || days.length == 0) {
    return '-'
  }

  days.forEach(day => {
    selectedDays.push(shortTitles[day])
  })

  return selectedDays.join(', ')
}

export const getDaysAsObject = () => {
  return [
    {
      "id": 1,
      "name": "Понедельник"
    },
    {
      "id": 2,
      "name": "Вторник"
    },
    {
      "id": 3,
      "name": "Среда"
    },
    {
      "id": 4,
      "name": "Четверг"
    },
    {
      "id": 5,
      "name": "Пятница"
    },
    {
      "id": 6,
      "name": "Суббота"
    },
    {
      "id": 0,
      "name": "Воскресенье"
    }
  ]
}

export const setDaysOfWeek = (days) => {
  const result = []

  days.forEach(day => {
    result.push({
      "id": day,
      "name": getDayTitle(day)
    })
  })

  return result
}