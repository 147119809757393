<template>
  <section>
    <div class="page-header page-header--filter-mobile">
      <div class="container">
        <div class="page-header__inner">
          <div class="page-header__left">
            <h1>Настройка уведомлений</h1>
          </div>
          <div class="search-block search-block--orders">
            <a class="header__btn btn btn--secondary btn--small pull-right" @click="showProfileModal = true">Изменить
              E-mail уведомлений</a>
          </div>
        </div>
      </div>
    </div>
    <div class="preloader-container" :class="{ 'preloader-container--loading': !showPlaceholders && isLoading }">
      <transition name="fade">
        <preloader v-if="!showPlaceholders && isLoading" />
      </transition>
      <div class="address-table">
        <div class="container">
          <address-list :addresses="addresses" />
        </div>
      </div>
      <paginate
            v-if="pagination.pageCount > 1"
            v-model="pagination.currentPage"
            :page-count="pagination.pageCount"
            :scrollable-element="catalogContainer"
            @scroll-paginate="onScrollPaginate"
            @paginate="paginate"
        />
    </div>
    <profile-modal v-model="showProfileModal" :user="user" />
  </section>
</template>

<script>

import { computed, ref } from 'vue'
import { useToast } from 'vue-toastification'
import Preloader from '@/components/Preloader.vue'
import { useAuth } from '@websanova/vue-auth/src/v3.js'
import { useStore } from 'vuex'
import { REMIND_ADDRESSES } from '@/store/modules/addresses/actionTypes'
import parsePaginationHeaders from '@/common/parsePaginationHeaders'
import Paginate from '@/components/Paginate'
import { useRouter } from 'vue-router'
import AddressList from '@/components/addresses/AddressList'
import ProfileModal from '@/components/ProfileModal.vue'

const showPlaceholders = ref(true)
const pagination = ref({})
const searchParams = computed(() => {
  return {
    page: pagination.value.currentPage
  }
})

const fetchAddresses = (store, toast, append) => {
  store.dispatch(REMIND_ADDRESSES, { append, params: searchParams.value }).then(({ headers }) => {
    pagination.value = parsePaginationHeaders(headers)
  }).catch(() => {
    toast.error('Не удалось загрузить список адресов')
  }).finally(() => {
    showPlaceholders.value = false
  })
}

export default {
  components: {
    Paginate,
    Preloader,
    AddressList,
    ProfileModal
  },
  beforeRouteEnter() {
    showPlaceholders.value = true
  },
  beforeRouteUpdate() {
    fetchAddresses(this.$store, this.$toast)
  },
  setup() {
    const auth = useAuth()
    const user = computed(() => auth.user())
    const toast = useToast()
    const store = useStore()
    const router = useRouter()

    const showProfileModal = ref(false)
    const showPlaceholders = ref(true)


    const isLoading = computed(() => store.getters.isAddressesLoading)
    const addresses = computed(() => store.getters.getAddresses)
    const updateRouter = () => {
      router.push({ path: '/reminder', query: searchParams.value })
    }
    const paginate = () => {
      updateRouter()
    }

    const onScrollPaginate = () => {
      const { currentPage, pageCount } = pagination.value
      if (!showPlaceholders.value && !isLoading.value && currentPage < pageCount) {
        pagination.value.currentPage++
        fetchAddresses(store, toast, true)
      }
    }

    fetchAddresses(store, toast)

    return {
      isLoading,
      showPlaceholders,
      addresses,
      paginate,
      pagination,
      updateRouter,
      onScrollPaginate,
      showProfileModal,
      user
    }
  }
}
</script>
