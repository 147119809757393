export const EVERYDAY = 0
export const DAY_OF_WEEK = 1
export const DAY_OF_MONTH = 2


const titles = {
  [EVERYDAY]: 'Раз в сутки',
  [DAY_OF_WEEK]: 'Дни недели',
  [DAY_OF_MONTH]: 'Календарный день/дни в месяце'
}


export const getTitle = (type) => {
  return titles[type]
}

export const getTypesAsObject = () => {
  return [
    {
        "id": 0,
        "name": "Раз в сутки"
    },
    {
        "id": 1,
        "name": "Дни недели"
    },
    {
        "id": 2,
        "name": "Календарный день/дни в месяце"
    }
  ]
}

export const getTypeAsObject = (type) => {
  return {
        "id": type,
        "name": getTitle(type)
    }
}